import React, { PureComponent } from "react";
import Header from "../components/Header";

import styles from "./ClassList.module.scss";
import LazyLoad from "react-lazyload";
import LoadMore from "../components/LoadMore";
import If from "../components/If";
import Report from "../components/Report";
import { getClassList } from "../service/api";
import webview from "../utils/webview";
import { fullScreen } from "../utils/variable";

const covers = {
  3149267: require(".././images/1a.jpg"),
  3149268: require(".././images/2a.jpg"),
  3149269: require(".././images/3a.jpg"),
  3149271: require(".././images/4a.jpg")
};

export default class ClassList extends PureComponent {
  state = {
    classes: [],
    index: 0,
    loading: true
  };

  loadMore() {
    getClassList(this.state.index + 1).then(res => {
      if (!res.data || !res.data.content)
        return console.error(`response error`);
      this.setState({
        classes: [...this.state.classes, ...res.data.content],
        index: res.data.page_token,
        loading: res.data.page_token === this.state.index
      });
    });
  }

  render() {
    return (
      <>
        <If condition={fullScreen}>
          <Header plain={true} onReturn={() => webview.close()}>
            Tất cả
          </Header>
        </If>
        <div
          className={styles.list}
          style={{ paddingTop: fullScreen ? 26 : "" }}
        >
          {this.state.classes.map((_class, index) => (
            <Report
              id={_class.id}
              category={_class.category}
              height={105}
              key={index}
            >
              <div
                className={styles.item}
                onClick={() => webview.jumpToHomePage(_class.id)}
              >
                <img src={covers[_class.id] || ""} alt="" />
                <div className={styles.content}>
                  <div className={styles.title}>{_class.nickname}</div>
                  <div className={`${styles.desc} multiRowTextOverflow`}>
                    {_class.description}
                  </div>
                  <div className={styles.meta}>
                    {_class.player_num} phút trước
                  </div>
                </div>
              </div>
            </Report>
          ))}
          <If condition={this.state.loading}>
            <LazyLoad overflow={false} height={40}>
              <LoadMore onLoadMore={() => this.loadMore()}></LoadMore>
            </LazyLoad>
          </If>
        </div>
      </>
    );
  }
}
