import { post } from "../utils/request";
import { should } from "../utils/variable";

const history = [];

//获取游戏推荐列表
export function getGameList() {
  return Promise.all([
    post("/tab2/content/list", {
      category: "game",
      page_token: 1
    }).then(res => {
      res.data.length = res.data.content.length;
      res.data.content = res.data.content.filter(
        item =>
          !(
            should &&
            (String(item.id) === "20753922" || String(item.id) === "20753381")
          )
      );
      return res;
    }),
    post("/tab2/content/list", {
      category: "game",
      page_token: 2
    }).then(res => {
      res.data.length = res.data.content.length;
      res.data.content = res.data.content.filter(
        item =>
          !(
            should &&
            (String(item.id) === "20753922" || String(item.id) === "20753381")
          )
      );
      return res;
    })
  ]).then(ress => {
    return {
      ret: 0,
      data: {
        content: [...ress[0].data.content, ...ress[1].data.content]
      }
    };
  });
}

//获取直播列表
export function getLiveStreamingList() {
  return post("/tab2/live_streaming/list_v2");
}

export function getClassList() {
  return post("/tab2/content/list", {
    page_token: 1,
    category: "excellent_course"
  });
}

//获取商品列表
export function getGoodList() {
  return post("/tab2/content/list", {
    page_token: 1,
    category: "e_commodity"
  });
}

//关注的头条号
export function getFollowList(index = 1) {
  return post("/v1/user/follows", {
    page_token: index === 1 ? "" : index,
    target_uid: true
  }).then(res => {
    res.data.length = res.data.list.length;
    res.data.list = res.data.list.filter(
      item =>
        !(
          should &&
          (String(item.id) === "20753922" || String(item.id) === "20753381")
        )
    );
    return res;
  });
}

export function getMaybeLike({ page_token, list_type }) {
  return post('/v2/user/list', {
    page_token,
    list_type
  })
}

//关注头条号
export function follow(target_uid) {
  return post("/v1/user/follow", {
    target_uid,
    source: 32
  });
}

//获取历史访问的头条号
export function getHistoryList(index = 1) {
  return post("/tab2/content/recommend/list", {
    page_token: index
  }).then(res => {
    res.data.length = res.data.content.length;
    res.data.content = res.data.content.filter(
      item =>
        !(
          should &&
          (String(item.id) === "20753922" || String(item.id) === "20753381")
        )
    );
    return res;
  });
}

const reportTask = [];
let inReportRun = 0;

function triggerTask() {
  if (reportTask[0]) {
    report({
      id: reportTask[0].id,
      category: reportTask[0].category,
      action: reportTask[0].action
    })
      .then(reportTask[0].resolve)
      .catch(reportTask[0].reject);
    reportTask.shift();
  }
}
if (!window.canReport) {
  window.vnnative = {
    onappear() {
      window.canReport = true;
      triggerTask();
    }
  };
}
//单条上报
export function report({ id, category, action }) {
  //tab2只有头条号，所以third_part只会为0,id只能是头条号id
  if (inReportRun === 2 || !window.canReport) {
    return new Promise((resolve, reject) => {
      reportTask.push({
        resolve,
        reject,
        id,
        category,
        action
      });
    });
  }
  inReportRun++;
  return post("/tab2/game/report", {
    third_part: 0,
    source: "tab2",
    id,
    category,
    action,
    label: "-"
  })
    .then(res => {
      inReportRun--;
      triggerTask();
      return res;
    })
    .catch(() => {
      inReportRun--;
      triggerTask();
    });
}

report.view = (id, category) => {
  id = String(id);
  if (history.indexOf(id) !== -1) return;
  history.push(id);
  report({ id, category, action: "view" });
};
let lastId = null;
let timeoutId = null;
report.visit = (id, category) => {
  if (id === lastId) return;
  report({ id, category, action: "visit" });
  lastId = id;
  timeoutId && clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    lastId = null;
  }, 2000);
};

//批量上报
export function batchReport({ actions }) {
  return post("/tab2/action/report", {
    actions
  });
}
