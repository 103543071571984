import React, { PureComponent } from "react";

export default class ImagePlaceholder extends PureComponent {
  state = {
    trueSrc: null,
    src: {
      game: require("../images/p1.png"),
      novel: require("../images/p2.png"),
      avatar: require("../images/p3.png"),
      good: require("../images/p4.png")
    }
  };

  handleLoad = () => {
    this.trueSrc = this.props.src;
    this.setState({
      trueSrc: this.props.src
    });
  };

  componentDidMount() {
    const image = new Image();
    image.src = this.props.src;
    image.onload = this.handleLoad;
  }

  render() {
    const { trueSrc } = this.state;
    const { className, alt } = this.props;
    if (trueSrc) {
      return (
        <img
          src={trueSrc}
          style={{ animation: "fate 1s", display: "block" }}
          className={className}
          alt={alt}
        ></img>
      );
    } else {
      return (
        <img
          src=""
          className={className}
          style={{ backgroundColor: "#f6f5f5", display: "block" }}
          alt={alt}
        ></img>
      );
    }
  }
}
