import React, { PureComponent } from "react";
import styles from "./NovelCard.module.scss";
import { report } from "../service/api";
import Swiper from "react-id-swiper";
import Card from "./Card";
import Report from "./Report";
import ImagePlaceholder from "./ImagePlaceholder";

export default class NovelCard extends PureComponent {
  state = {
    pages: [
      [
        {
          coverimg:
            "http://images2.vnstory.xyz/vnstory/1d/ce/1dce080cc7f4c9af1dd2054b7cb9feb9.jpeg",
          business_url:
            "http://m.medoctruyenchu.net/#/storyDetail/55574931?author_id=20753127",
          id: "20753127",
          title: "Binh vương trở thành ông bố bỉm sữa",
          summary:
            "Tôi vốn là một binh vương, sau khi giải ngũ tôi được sắp xếp một công việc, đó chính là giả làm chồng của nữ tổng giám đốc xinh đẹp, thay cô ấy chăm sóc con cái.\nNhưng, một cô gái xinh đẹp như vậy, bổn binh vương đương nhiên sẽ không buông tha, nếu có làm thì phải làm người chồng chân chính của cô ấy."
        },
        {
          coverimg:
            "http://images2.vnstory.xyz/vnstory/47/63/47634f82ce8c53ba66261064fcdffb21.jpeg",
          business_url:
            "http://m.medoctruyenchu.net/#/storyDetail/55559599?author_id=20753934",
          id: "20753934",
          title: "Kết hôn chớp nhoáng - Tổng tài ly hôn đi",
          summary:
            'Không cẩn thận lên nhầm giường, ngủ nhầm người. Vậy mà người thừa kế của tập đoàn Hoàng Đế, vung tay là có thể hô phong hoán vũ,  lại đuổi theo cô đòi chịu trách nhiệm. Ký kết một hợp đồng hôn nhân nửa năm, đến kì hạn rồi lại áp tường, áp giường, áp cả thang máy. Kẻ nào đó ban ngày đã bại hoại, ban đêm bại hoại còn không bằng. Cuối cùng cô không nhịn được nữa liền "Tổng tài đại nhân, ly hôn đi". Hắn hất mày nói: "Xem ra anh chưa nói rõ, kỳ hạn của hợp đồng hôn nhân này là--- một đời người"'
        }
      ],
      [
        {
          coverimg:
            "http://images2.vnstory.xyz/vnstory/64/05/6405ff950f4d083c4501c83d8092539b.jpeg",
          business_url:
            "http://m.medoctruyenchu.net/#/storyDetail/55576563?author_id=20753627",
          id: "20753627",
          title: "Lớp học tuyệt vọng",
          summary:
            "Vốn chỉ là một trò chơi bỏ phiếu được ai đó ác ý tạo nên, nhưng không ngờ thảm kịch xảy ra không ngớt, bạn học trong lớp từng người từng người chết đi. Mà tôi biết, người tiếp theo, có lẽ là tôi..."
        },
        {
          coverimg:
            "http://images1.vnstory.xyz/vnstory/41/30/4130e5fdd8307498cc9485ca4d625fe6.jpeg",
          business_url:
            "http://m.medoctruyenchu.net/#/storyDetail/55589471?author_id=20752992",
          id: "20752992",
          title: "Cực phẩm chiến binh",
          summary:
            "Anh được tôn xưng là vua của giới bộ đội đặc chủng, anh lại ông vua thần bí của thế giới ngần. Tiêu Binh, anh vốn đã đứng ở đỉnh cao thế giới, lại vì một hồng nhan tri kỷ mà quay về thành phố bình thường, bắt đầu lại từ đầu, ngược dòng đi lên, lòng bàn chân giẫm lên hài cốt, trong lòng ôm mỹ nhân, thành tựu thống trị bất diệt...\n"
        }
      ],
      [
        {
          coverimg:
            "http://images2.vnstory.xyz/vnstory/5c/63/5c63308d61f4102d2c29aa61fc6e21b5.jpeg",
          business_url:
            "http://m.medoctruyenchu.net/#/storyDetail/55591403?author_id=20753165",
          title: "Độc nhất sủng hôn",
          id: "20753165",
          summary:
            "Cha và tiểu tam cấu kết với nhau hại chết mẹ, lại còn bán cô cho một tên cặn bã, phải làm sao đây? Vào lúc quan trọng nhất, soái ca anh tú xuất hiện với một bản hợp đồng trên tay. Tân Tinh căm giận nhìn người trước mặt. Tưởng đâu là bạch mã hoàng tử, ai dè lại là ác ma. “Không được trốn, em chỉ có thể gả cho tôi thôi!” Anh gầm gừ dằn mặt cô gái đang tính bỏ đi. Tân Tinh cười lạnh: “Chẳng phải anh cũng vì tổ huấn mới giữ tôi lại sao? Tôi chỉ là công cụ của anh thôi!” “Ai nói?” Ánh mắt anh đầy thâm tình: “Em là mẹ của con anh!”"
        },
        {
          coverimg:
            "http://images2.vnstory.xyz/vnstory/70/44/7044132af9245bbc5b53cfb661dcb79c.jpeg",
          business_url:
            "http://m.medoctruyenchu.net/#/storyDetail/55590750?author_id=20753388",
          title: "Chung cực giáo sư",
          id: "20753388",
          summary:
            "Cao thủ Thái cực vì chạy trốn vị hôn thê điêu ngoa hung dữ mà chạy đến thành phố làm giáo viên, mỹ nữ hoa khôi vừa nhìn thấy liền nhất kiến chung tình, ngự tỷ nữ hiệu trưởng diễn Phụng cầu hoàng, còn vô số mỹ nữ Loli ưu ái có thừa, Phương Viêm tung hoành sân trường tiêu dao vô biên!"
        }
      ]
    ]
  };

  link(item) {
    report.visit(item.id, "novel");
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "story",
        applicationName: item.id
      });
    window.location.href = `vntopnewslocal://user/businesspage?user_id=${item.id}&from=tab2`;
  }

  handleSlideChange = () => {
    const { activeIndex } = this.swiper;
    if (activeIndex === 1) {
      this.state.pages[2].forEach(novel => {
        report.view(novel.id, "novel");
      });
    }
  };

  render() {
    const { pages } = this.state;
    return (
      <div className={styles.NovalCardWrapper}>
        <Card
          title="Tiểu thuyết hot"
          more="http://m.medoctruyenchu.net/?author_id=20753633"
        >
          <Swiper
            getSwiper={swiper => (this.swiper = swiper)}
            slidesPerView={1.32}
            slidesOffsetBefore={18}
            slidesOffsetAfter={76 * window.rate}
            on={{ slideChangeTransitionEnd: this.handleSlideChange }}
          >
            {pages.map((novals, mIndex) => (
              <div className={styles.page} key={mIndex}>
                {novals.map((noval, index) => (
                  <Report
                    key={index}
                    height={84}
                    category="novel"
                    id={mIndex <= 1 ? noval.id : null}
                  >
                    <div
                      className={styles.item}
                      onClick={this.link.bind(this, noval)}
                    >
                      <ImagePlaceholder
                        src={noval.coverimg}
                        alt=""
                        type="novel"
                      ></ImagePlaceholder>
                      <div className={styles.content}>
                        <div className={styles.name}>{noval.title}</div>
                        <div className={styles.desc + " multiRowTextOverflow"}>
                          {noval.summary}
                        </div>
                        <div className={styles.meta}>
                          {/* <div className={styles.comment}>
                                <img src={require('../images/find_comment.png')}></img>
                                <span>677</span>
                              </div>
                              <div className={styles.browse}>
                                <img src={require('../images/find_see.png')}></img>
                                <span>677</span>
                              </div> */}
                          <div className={styles.time}>29-07-2019</div>
                        </div>
                      </div>
                    </div>
                  </Report>
                ))}
              </div>
            ))}
          </Swiper>
        </Card>
      </div>
    );
  }
}
