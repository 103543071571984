import webview from "./webview";
import Cookies from "js-cookie";

const isDev = process.env.NODE_ENV === "development";

function getUserInfo() {
  if (process.env.NODE_ENV === 'development') {
    return Promise.resolve({
      uid: Cookies.get("uid") || (isDev ? "25385014" : ""),
      login_token:
        Cookies.get("login_token") ||
        (isDev ? "cb525208a49160f1210f6dabac1d6b5bbb2a3cf0" : "")
    })
  }
  return webview
    .userInfo()
    .then(response => ({
      uid: response.uid,
      login_token: response.login_token
    }))
    .catch(() => ({
      uid: Cookies.get("uid") || (isDev ? "25385014" : ""),
      login_token:
        Cookies.get("login_token") ||
        (isDev ? "cb525208a49160f1210f6dabac1d6b5bbb2a3cf0" : "")
    }));
}

function getDeviceInfo() {
  if (process.env.NODE_ENV === 'development') {
    return Promise.resolve({
      client: Cookies.get('client') || '',
      version: Cookies.get('version') || ''
    })
  }
  return webview
    .environment()
    .then(response => ({
      client: response.client,
      version: response.version
    }))
    .catch(() => ({
      client: Cookies.get('client') || '',
      version: Cookies.get('version') || ''
    }));
}

async function getPublicParams() {
  const userInfo = await getUserInfo();
  const deviceInfo = await getDeviceInfo();
  return { ...userInfo, ...deviceInfo };
}

export async function mergeToObject(obj, map = []) {
  console.log(123)
  const publicParams = await getPublicParams();
  return { ...publicParams, ...obj };
}
