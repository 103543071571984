window.vntopnews = window.vntopnews || {};
window.vntopnews.jsbridge =
  window.vntopnews.jsbridge ||
  function(arg) {
    window.webkit.messageHandlers.vntopnews.postMessage({
      action: "jsbridge",
      method: JSON.parse(arg)
    });
  };

const jsbridge = (window.vntopnews || {}).jsbridge;

const jsbridgeTask = [];
let isJsbridgeWorking = false;

// 执行任务栈中第一个任务
function triggleTask() {
  if (jsbridgeTask[0]) {
    jsbridgeCompact(jsbridgeTask[0].args)
      .then(jsbridgeTask[0].resolve)
      .catch(jsbridgeTask[0].reject);
    jsbridgeTask.shift();
  }
}

const jsbridgeCompact = ({
  method,
  url,
  params = {},
  callback = `jsbridgeCallback${Date.now()}`
}) => {
  if (!jsbridge) {
    return Promise.reject({});
  }
  // 如果有正在运行的任务，则保存当前任务到任务栈
  if (isJsbridgeWorking) {
    return new Promise((resolve, reject) => {
      jsbridgeTask.push({
        resolve,
        reject,
        args: {
          method,
          url,
          params,
          callback
        }
      });
    });
  }
  isJsbridgeWorking = true;

  const query = () =>
    window.vntopnews.jsbridge(
      JSON.stringify({
        method,
        params,
        callback
      })
    );

  return new Promise((resolve, reject) => {
    window[callback] = jsonString => {
      // 当前任务处理完毕，开始执行任务队列中的任务
      isJsbridgeWorking = false;
      triggleTask();
      url && console.log(url);
      const response = JSON.parse(jsonString) || {};

      const { params = {} } = response;

      if (!params || Object.keys(params).length === 0) {
        reject(new Error(`JsBridge error: ${jsonString}`));
      } else {
        resolve(params);
      }
    };

    query();
  });
};

export default {
  // /**
  //  * 登录
  //  *
  //  * | mode | 登录方式 | 空：弹出选择；facebook：facebook登录；phone：手机号登录 |
  //  * | callback_func | 分享后执行的js方法名，1(成功)/2(失败)/3(取消) | |
  //  *
  //  */
  // login: (mode, cb) => {
  //   let search = mode ? `mode=${mode}&` : '';

  //   if(_.isFunction(cb)) {
  //     window.login_callback = cb;
  //     search += 'callback_func=login_callback';
  //   }
  //   window.location.href = `vntopnewslocal://login?${search}`;
  // },

  // /**
  //  * 账号绑定
  //  *
  //  * | mode | 绑定方式 | facebook：facebook登录；phone：手机号登录 |
  //  * | callback_func | 分享后执行的js方法名，1(成功)/2(失败)/3(取消) | |
  //  *
  //  */
  // accountBind: (mode, cb) => {
  //   let search = `mode=${mode}&`;

  //   if(_.isFunction(cb)) {
  //     window.account_bind_callback = cb;
  //     search += 'callback_func=account_bind_callback';
  //   }
  //   window.location.href = `vntopnewslocal://account_bind?${search}`;
  // },

  // /**
  //  * 新页面中打开WebView
  //  *
  //  * title：标题
  //  * url：打开的页面地址
  //  */
  jump: ({ direct = false, ...args }) => {
    if (direct || process.env.NODE_ENV === 'development') {
      window.location.href = args.url;
      return;
    }
    args.url = encodeURIComponent(args.url);
    let search = "";
    for (let arg in args) {
      search += `${arg}=${args[arg]}&`;
    }
    search = search.slice(0, search.length - 1);
    window.location.href = `vntopnewslocal://webview?${search}&trusted=1`;
  },

  jumpToBusinessPage(id) {
    window.location.href = `vntopnewslocal://user/businesspage?user_id=${id}&from=tab2`;
  },
  jumpToHomePage(id) {
    window.location.href = `vntopnews://user/homepage?user_id=${id}&from=tab2`;
  },
  jumpToBigoRoom(id, type='follow') {
    window.location.href = `vntopnewslocal://bigolive/room?room_id=${id}&room_type=${type}`
  },
  getBigoFollowRoomInfo: () => jsbridgeCompact({ method: 'getBigoFollowRoomInfo' }),
  getBigoHotRoomInfo: () => jsbridgeCompact({ method: 'getBigoHotRoomInfo' }),
  // video: (args) => {
  //   window.location.href = `vntopnews://video?${stringify(args)}`;
  // },

  // article: (args) => {
  //   window.location.href = `vntopnews://article?${stringify(args)}`;
  // },

  // moment: (args) => {
  //   window.location.href = `vntopnewslocal://moments/detail?${stringify(args)}`;
  // },

  close: () => {
    window.location.href = "vntopnewslocal://webview/close";
  },

  userInfo: args => jsbridgeCompact({ method: "userInfo", ...args }),
  environment: args => jsbridgeCompact({ method: "environment", ...args })
  // secretData: args => jsbridgeCompact({ method: 'secretData', ...args }),
  // noSecretData: args => jsbridgeCompact({ method: 'noSecretData', ...args }),
  // permissionSetting: args => jsbridgeCompact({ method: 'permissionSetting', ...args }),
  // toast: args => jsbridgeCompact({ method: 'toast', ...args }),
};
