import React, { PureComponent } from "react";
import { report } from "../service/api";
import styles from "./Card.module.scss";
import webview from "../utils/webview";
import { fullScreen } from "../utils/variable";

const isAndroid = navigator.appVersion.indexOf("ndroid") !== -1;

export default class Card extends PureComponent {
  link() {
    if (this.props.onMore) {
      return this.props.onMore();
    }

    const { more } = this.props;
    if (more.indexOf("game") > -1) {
      window.dataLayer &&
        window.dataLayer.push({
          event: "click",
          action: "click",
          cardType: "game",
          applicationName: "more"
        });
      webview.jump({
        url: more,
        page_cache: 1,
        page_fullscreen: fullScreen ? 1 : 0,
        page_titlebar: fullScreen ? 0 : 1
      });
    } else if (more.indexOf("medoctruyenchu") > -1) {
      window.location.href = `vntopnewslocal://user/businesspage?user_id=${20753633}&from=tab2`;
      window.dataLayer &&
        window.dataLayer.push({
          event: "click",
          action: "click",
          cardType: "story",
          applicationName: "more"
        });
      report.visit(20753633, "novel");
    } else {
      return false;
    }
  }

  handleClick() {
    webview.jump({
      // url: 'http://' + document.domain + '/static/game/shoplist',
      url: `https://lottery.headlines.pw/static/v2/#/offerwall/integral?showmodalid=${
        isAndroid ? "8033" : "8034"
      }`,
      // page_fullscreen: fullScreen ? 1 : 0,
      page_fullscreen: 0,
      // page_titlebar: fullScreen ? 0 : 1
      page_titlebar: 1
    });
  }

  render() {
    const content =
      this.props.loading === true ? (
        <img
          className={styles.loading}
          src={require("../images/loading.png")}
          alt=""
        ></img>
      ) : (
        this.props.children
      );

    const more =
      this.props.extra !== false ? (
        <>
          <div onClick={this.link.bind(this)} className={styles.extra}>
            Xem thêm
          </div>
          <img
            onClick={this.link.bind(this)}
            src={require("../images/find_arrow.png")}
            alt=""
          ></img>
        </>
      ) : null;

    return (
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.title}>{this.props.title}</div>
          {more}
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    );
  }
}
