import React, { PureComponent } from "react";
import styles from "./ShopCard.module.scss";
import { report } from "../service/api";
import Swiper from "react-id-swiper";
import Card from "./Card";
import Report from "./Report";
import webview from "../utils/webview";
import If from "../components/If";

export default class ShopCard extends PureComponent {
  state = {
    goods: [
      // {
      //   nickname: 'Giới hạn thời gian rút thưởng lớn',
      //   id: '20753675',
      //   avatar: require('../images/phone_card.png'),
      //   tag: 'new'
      // },
      // {
      //   nickname: 'Tai nghe Bluetooth I7S',
      //   prise: '168000',
      //   id: '20753336',
      //   oldPrise: '1008000',
      //   avatar: require('../images/min.png')
      // },
      // {
      //   nickname: 'Mặt nạ dưỡng da Hàn Quốc',
      //   prise: '250000',
      //   id: '20753810',
      //   oldPrise: '399000',
      //   avatar: require('../images/mianmo.png')
      // },
      // {
      //   nickname: 'Thưởng thức âm thanh HiFi tạo cho bạn như đang ở buổi tiệc âm nhạc của mình ',
      //   prise: '688900',
      //   id: '20753367',
      //   oldPrise: '988900',
      //   avatar: 'https://image2.vnay.vn/topnews-2017/imgs/b3/e2/b3e20e564fc7609a2a3b7b987e173a6e_m.jpg'
      // },
      {
        nickname: "bàn chải đánh răng điện cấu hình cao.",
        prise: "1030000",
        oldPrise: "2030000",
        id: "20753499",
        avatar:
          "https://image2.vnay.vn/topnews-2017/imgs/7e/b2/7eb2cce8088ea43e10d5c81ee34acbab_m.jpg"
      },
      {
        nickname: "Thưởng thức thời khắc âm nhạc của chính mình.",
        prise: "350000",
        id: "20753040",
        oldPrise: "650000",
        avatar: require("../images/yinxiang.jpg")
      },
      {
        nickname: "Thưởng thức âm nhạc mọi lúc mọi nơi",
        prise: "655000",
        id: "20753827",
        oldPrise: "955000",
        avatar: require("../images/phone.jpg")
      }
    ],
    loading: false
  };

  componentDidMount() {
    // bindLoading(
    //   getGoodList()
    //   .then(res => {
    //     this.setState({
    //       goods: res.data.content
    //     })
    //   }),
    //   loading => this.setState({ loading })
    // )
  }

  handleMore = () => {
    webview.jump({
      // url: 'http://' + document.domain + '/static/game/shoplist',
      url: "http://shop.kumobiles.com/ldy/yougou/index.html",
      // page_fullscreen: fullScreen ? 1 : 0,
      page_fullscreen: 0,
      // page_titlebar: fullScreen ? 0 : 1
      page_titlebar: 1
    });
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "good",
        applicationName: "more"
      });
  };

  handleSlideChange = () => {
    const { activeIndex } = this.swiper;
    if (activeIndex < this.state.goods.length - 1) {
      const good = this.state.goods[activeIndex + 1];
      report.view(good.id, "e_commodity");
    }
  };

  link(item) {
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "good",
        applicationName: item.id
      });
    report.visit(item.id, "good");
  }

  render() {
    return (
      <div className={styles.ShopCardWrapper}>
        <Card title="VN NGÀY NAY SHOP" onMore={this.handleMore}>
          <If condition={this.state.goods.length > 0}>
            <Swiper
              getSwiper={swiper => (this.swiper = swiper)}
              on={{ slideChangeTransitionEnd: this.handleSlideChange }}
              slidesPerView="auto"
              slidesOffsetAfter={10 * window.rate}
            >
              {this.state.goods.map((good, index) => (
                <div
                  className={styles.item}
                  key={index}
                  onClick={() => {
                    webview.jumpToBusinessPage(good.id);
                    this.link(good);
                  }}
                >
                  <If condition={good.tag}>
                    <div className={styles.tag}>{good.tag}</div>
                  </If>
                  <Report
                    height={120}
                    key={index}
                    category="e_commodity"
                    id={index <= 2 ? good.id : null}
                  >
                    <img src={good.avatar} alt="" />
                  </Report>
                  <div className={`${styles.desc} multiRowTextOverflow`}>
                    {good.nickname}
                  </div>
                  <If condition={good.prise && good.oldPrise}>
                    <div className={styles.prise}>
                      <div className={styles.prev}>
                        {(+good.oldPrise / 1000).toLocaleString() + "k"}
                      </div>
                      <div className={styles.now}>
                        {(+good.prise / 1000).toLocaleString() + "k"}
                      </div>
                    </div>
                  </If>
                </div>
              ))}
            </Swiper>
          </If>
        </Card>
      </div>
    );
  }
}
