import React, { PureComponent } from "react";
import Swiper from "react-id-swiper";
import styles from "./Banner.module.scss";

export default class Banner extends PureComponent {
  render() {
    return (
      <Swiper>
        <img className={styles.cover} style={{ top: 26 }} src="" alt="" />
      </Swiper>
    );
  }
}
