import React, { PureComponent } from "react";
import styles from "./ClassCard.module.scss";
import { report, getClassList } from "../service/api";
import Swiper from "react-id-swiper";
import Card from "./Card";
import Report from "./Report";
import webview from "../utils/webview";
import { bindLoading } from "../utils/index";
import { fullScreen } from "../utils/variable";

const covers = {
  3149267: require(".././images/1a.jpg"),
  3149268: require(".././images/2a.jpg"),
  3149269: require(".././images/3a.jpg"),
  3149271: require(".././images/4a.jpg")
};
export default class ClassCard extends PureComponent {
  state = {
    classes: [],
    loading: false
  };

  componentDidMount() {
    bindLoading(
      getClassList().then(res => {
        this.setState({
          classes: res.data.content
        });
      }),
      loading => this.setState({ loading })
    );
  }

  handleMore = () => {
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "class",
        applicationName: "more"
      });
    webview.jump({
      url: "http://" + document.domain + "/static/game/classlist",
      page_fullscreen: fullScreen ? 1 : 0,
      page_titlebar: fullScreen ? 0 : 1
    });
  };

  link(item) {
    // const url = item.business_url
    // if (!url) {
    //   return Toast.fail('Liên kết không hợp lệ')
    // }
    // report.visit(item.id, 'novel')
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "class",
        applicationName: item.id
      });
    // window.location.href = `vntopnewslocal://user/businesspage?user_id=${item.id}&from=tab2`
  }

  handleSlideChange = () => {
    const { activeIndex } = this.swiper;
    if (activeIndex < this.state.classes.length - 1) {
      const _class = this.state.classes[activeIndex + 1];
      report.view(_class.id, _class.category);
    }
  };

  render() {
    return (
      <div className={styles.ClassCardWrapper}>
        <Card
          title="Khoá học hot"
          onMore={this.handleMore}
          loading={this.state.loading}
        >
          <Swiper
            getSwiper={swiper => (this.swiper = swiper)}
            on={{ slideChangeTransitionEnd: this.handleSlideChange }}
            slidesPerView="auto"
            slidesOffsetAfter={10 * window.rate}
          >
            {this.state.classes.map((_class, index) => (
              <div
                className={styles.item}
                key={index}
                onClick={() => {
                  webview.jumpToHomePage(_class.id);
                  this.link(_class);
                }}
              >
                <Report
                  height={110}
                  key={index}
                  category={_class.category}
                  id={index <= 1 ? _class.id : null}
                >
                  <img src={covers[_class.id] || ""} alt="" />
                </Report>
                <div className={styles.imageBackground}></div>
                <div className={styles.meta}>{_class.player_num} người học</div>
                <div className={styles.title}>{_class.nickname}</div>
                <div className={`${styles.desc} multiRowTextOverflow`}>
                  {_class.description}
                </div>
              </div>
            ))}
          </Swiper>
        </Card>
      </div>
    );
  }
}
