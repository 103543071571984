import React, { PureComponent } from "react";
import styles from "./Home.module.scss";
import Cookies from 'js-cookie'
import verzon from '../utils/verzon'

import Header from "../components/Header";
import MiniApp from "../components/MiniApp";
import NovelCard from "../components/NovelCard";
import LifeCard from "../components/LifeCard";
import HeadlineCard from '../components/HeadlineCard'
import ShopCard from "../components/ShopCard";
import ClassCard from "../components/ClassCard";
import NewShopCard from "../components/NewShopCard";
import If from "../components/If";
import LiveStreamingCard from '../components/LiveStreamingCard'
import { showHeader } from "../utils/variable";

const isAndroid = navigator.appVersion.indexOf("ndroid") !== -1;

export default class Home extends PureComponent {
  render() {
    const bigoCardVisible = verzon(Cookies.get('version')).weight >= verzon('3.18.7').weight && isAndroid
    return (
      <>
        <If condition={showHeader}>
          <Header>Khám phá</Header>
        </If>
        <div className={styles.content}>
          <MiniApp subscript={bigoCardVisible}></MiniApp>
          <HeadlineCard></HeadlineCard>
          {/* <If condition={String(Cookies.get('uid')) === '13170399' || String(Cookies.get('uid')) === '26915502'}>    */}
          <NewShopCard></NewShopCard>
          {/* </If> */}
          {/* <GameCard></GameCard> */}
          {bigoCardVisible && <LiveStreamingCard></LiveStreamingCard>}
          {/* <NovelCard></NovelCard> */}
          <ShopCard></ShopCard>
          <ClassCard></ClassCard>
          <LifeCard></LifeCard>
        </div>
      </>
    );
  }
}
