import React, { PureComponent } from "react";
import styles from "./DiamondMallGameCard.module.scss";
import ImagePlaceholder from "./ImagePlaceholder";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/css/swiper.css";
import Card from "./Card";
import { getGameList, report } from "../service/api";
import { bindLoading } from "../utils/index";
// import webview from '../utils/webview';

export default class DiamondMallGameCard extends PureComponent {
  state = {
    pages: [],
    loading: false,
    swiper: null
  };

  loadData() {
    bindLoading(
      getGameList().then(res => {
        if (!res.data || !res.data.content)
          return console.error("response error", res);
        const games = res.data.content.slice(0, 13);
        const insertGames = [
          {
            nickname: "Dự đoán cực vui",
            id: 19840070,
            player_num: 92000,
            description:
              "Rồng? Vẫn là Phoenix? Chờ đợi cho bạn để đoán, có rất nhiều phần thưởng xu!",
            avatar:
              "https://images.headlines.pw/topnews-2017/imgs/0d/1d/0d1da33a4f53b032b35df817c5f98277_128_128.jpg"
          },
          {
            nickname: "TRUY TÌM QUÁI THÚ",
            id: 20753840,
            desdescriptionc:
              "Trở thành Trại Chủ, đánh gục quái thú và kiếm cực nhiều Xu!",
            player_num: 61000,
            avatar:
              "https://images.headlines.pw/topnews-2017/imgs/12/2f/122f5abc091f07b913f4a3b041aaad00_128_128.jpg"
          },
          {
            nickname: "GAME",
            id: 18361544,
            description: "+1000xu! 1 vs 1 Hỏi nhanh đáp nhanh！",
            player_num: 55000,
            avatar:
              "https://images.headlines.pw/topnews-2017/imgs/31/7c/317cceefd2ee3e89a892f5e08a01d2e4_128_128.jpg"
          }
        ];
        const result = [];
        for (let i = 0; i < 15; i++) {
          if (i === 1) {
            result.push(insertGames[0]);
          } else if (i === 3) {
            result.push(insertGames[1]);
          } else if (i === 5) {
            result.push(insertGames[2]);
          } else {
            if (!games[0]) break;
            result.push(games[0]);
            games.splice(0, 1);
          }
        }
        const pages = [];
        while (result.length > 0) {
          const page = result.splice(0, 3);
          pages.push(page);
          if (pages.length === 5) break;
        }
        this.setState({
          pages
        });
        [...pages[0], ...pages[1]].forEach(game => {
          report.view(game.id, "game");
        });
      }),
      loading => this.setState({ loading })
    );
  }

  link(game) {
    // const url = game.business_url
    // if (!url) {
    //   return Toast.fail('Liên kết không hợp lệ')
    // }
    report.visit(game.id, "game");
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "game",
        applicationName: game.id
      });
    window.location.href = `vntopnewslocal://user/businesspage?user_id=${game.id}&from=tab2`;
  }

  handleSlideChange = () => {
    const { activeIndex } = this.swiper;
    if (activeIndex === 1) {
      this.state.pages[2].forEach(game => {
        report.view(game.id, "game");
      });
    }
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { pages, loading } = this.state;
    return (
      <div className={styles.GameCardWrapper}>
        <Card
          className={styles.GameCardWrapper}
          title="Game đề xuất"
          extra={false}
          loading={loading}
        >
          <Swiper
            getSwiper={swiper => (this.swiper = swiper)}
            slidesPerView={1.283}
            slidesOffsetAfter={88 * window.rate}
            on={{ slideChangeTransitionEnd: this.handleSlideChange }}
          >
            {pages.map((page, index) => (
              <div className={styles.column} key={index}>
                {page.map(game => (
                  <div
                    className={styles.game}
                    key={game.nickname}
                    onClick={this.link.bind(this, game)}
                  >
                    {/* <img src={game.avatar} alt=""></img> */}
                    <ImagePlaceholder
                      src={game.avatar}
                      type="game"
                    ></ImagePlaceholder>
                    <div className={styles.content}>
                      <div className={styles.name}>{game.nickname}</div>
                      <div className={styles.desc}>{game.description}</div>
                      <div className={styles.hot}>
                        {game.player_num > 1000
                          ? Math.ceil(game.player_num / 1000) + "k"
                          : game.player_num}{" "}
                        đang chơi
                      </div>
                    </div>
                    <div
                      className={styles.play}
                      onClick={this.link.bind(this, game)}
                      style={{
                        backgroundImage: `url(${require("../images/game_button.png")})`
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            ))}
          </Swiper>
        </Card>
      </div>
    );
  }
}
