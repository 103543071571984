import React, { PureComponent } from "react";
import styles from "./DiamondMall.module.scss";

import Header from "../components/Header";
import If from "../components/If";
import { fullScreen } from "../utils/variable";
import webview from "../utils/webview";
import Banner from "../components/Banner";
import DiamondMallGameCard from "../components/DiamondMallGameCard";

export default class DiamondMall extends PureComponent {
  render() {
    return (
      <div className={styles.DiamondMall}>
        <If condition={fullScreen}>
          <Header plain={true} onReturn={() => webview.close()}>
            Diamond Mall
          </Header>
        </If>
        <div className={styles.tip} style={{ marginTop: 26 }}>
          100.000VND
          <div className={styles.button}>Nạp</div>
        </div>
        <Banner></Banner>
        <DiamondMallGameCard></DiamondMallGameCard>
      </div>
    );
  }
}
