import React, { PureComponent } from "react";
import styles from "./LiveStreamingCard.module.scss";
import { report } from "../service/api";
import Swiper from "react-id-swiper";
import Card from "./Card";
import Report from "./Report";
import webview from "../utils/webview";
import { bindLoading } from "../utils/index";
import { getLiveStreamingList } from "../service/api";
import If from "../components/If";

export default class LiveStreamingCard extends PureComponent {
  state = {
    anchors: [],
    loading: false
  };

  componentDidMount() {
    bindLoading(
      webview.getBigoHotRoomInfo().then(res => {
        this.setState({
          anchors: res.room_list
        });
      }),
      loading => this.setState({ loading })
    );
    setInterval(() => {
      webview.getBigoHotRoomInfo().then(res => {
        this.setState({
          anchors: []
        });
        this.forceUpdate();
        this.setState({
          anchors: res.room_list
        });
      });
    }, 1000 * 60);
  }

  handleMore = () => {
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "live_streaming",
        applicationName: "more"
      });
    window.location.href = 'vntopnewslocal://bigolive'
  };

  link(item) {
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "live_streaming",
        applicationName: item.id
      });
    report.visit(item.id, "anchor");
  }

  render() {
    return (
      <div className={styles.ShopCardWrapper}>
        <Card
          title="Hot girls"
          onMore={this.handleMore}
          loading={this.state.loading}
        >
          <If condition={this.state.anchors.length > 0}>
            <Swiper
              getSwiper={swiper => (this.swiper = swiper)}
              slidesPerView="auto"
              slidesOffsetAfter={10 * window.rate}
            >
              {this.state.anchors.map((anchor, index) => (
                // <div className={styles.item} key={index} onClick={['30922866', '33198563', '31999850', '32035190', '26915502'].indexOf(Cookies.get('uid')) !== -1 ? () => webview.jump({ url: anchor.jump_link}) : this.handleMore}>
                <div
                  className={styles.item}
                  key={index}
                  onClick={() => webview.jumpToBigoRoom(anchor.id, 'hot')}
                >
                  <Report
                    height={120}
                    key={index}
                    category="live_streaming"
                    id={null}
                  >
                    <img src={anchor.cover} alt="" />
                  </Report>
                  <div className={`${styles.desc} multiRowTextOverflow`}>
                    {anchor.title}
                  </div>
                </div>
              ))}
            </Swiper>
          </If>
        </Card>
      </div>
    );
  }
}
