import React, { PureComponent } from "react";
import styles from "./NewShopCard.module.scss";
import Card from "./Card";
import Swiper from "react-id-swiper";
import If from "./If";
import Report from "./Report";
import webview from "../utils/webview";
import { Autoplay } from "swiper/dist/js/swiper.esm";

export default class NewShopCard extends PureComponent {
  state = {
    banner: [
      {
        image: require('../images/shop/690X320/11.jpg'),
        link: "https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2FCollections%2F371507"
      },
      {
        image: require('../images/shop/690X320/12.jpg'),
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2Fcollections%2F312811%2F'
      },
      {
        image: require('../images/shop/690X320/13.jpg'),
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2Fcollections%2F369568%2F'
      },
      {
        image: require('../images/shop/690X320/17.jpg'),
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2Fcollections%2F341455%2F'
      }
    ],
    goods: [
      {
        image: require("../images/shop/240x240/2.jpg"),
        desc: "Deal đã hời còn được hoàn xu",
        link:
          "https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2F%25C3%2581o-len-c%25E1%25BB%2595-l%25E1%25BB%258D-m%25E1%25BB%2581m-%25E1%25BA%25A5m-th%25E1%25BB%259Di-trang-(-Ch%25E1%25BA%25A5t-len-%25C4%2591%25E1%25BA%25B9p)-i.44651390.3112113435"
      },
      {
        image: require("../images/shop/240x240/3.jpg"),
        desc: 'Giảm giá ngập tràn, săn ngay kẻo lỡ',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2FSet-10-nh%25E1%25BA%25ABn-kim-lo%25E1%25BA%25A1i-phong-c%25C3%25A1ch-H%25C3%25A0n-Qu%25E1%25BB%2591c-h%25E1%25BB%25A3p-th%25E1%25BB%259Di-trang-d%25C3%25A0nh-cho-n%25E1%25BB%25AF-i.129895965.2137584218'
      },
      {
        image: require("../images/shop/240x240/4.jpg"),
        desc: 'Flashsale giày hiệu giá rẻ vô địch',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2FM%25E1%25BA%25AAU-GU%25E1%25BB%2590C-M%25C5%25A8I-NH%25E1%25BB%258CN-%25C4%2590%25E1%25BA%25B8P-XU%25E1%25BA%25A4T-S%25E1%25BA%25AEC-i.7332956.1951937256'
      },
      {
        image: require("../images/shop/240x240/5.jpg"),
        desc: 'Nhanh tay nhập mã, lập tức được hoàn xu',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2F-M%25C3%25A3-WBMAX20-gi%25E1%25BA%25A3m-Gi%25E1%25BA%25A3m-20-t%25E1%25BB%2591i-%25C4%2591a-20K-%25C4%2591%25C6%25A1n-B%25E1%25BA%25A5t-k%25E1%25BB%25B3-T%25C3%25BAi-tote-nhung-%25C4%2591eo-vai-phong-c%25C3%25A1ch-H%25C3%25A0n-Qu%25E1%25BB%2591c-TV28-i.55849425.2598257724'
      },
      {
        image: require("../images/shop/240x240/6.jpg"),
        desc: 'Giày siêu đẹp, giá siêu rẻ',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2FGi%25C3%25A0y-nam-da-b%25C3%25B2-th%25E1%25BA%25ADt-X%25C6%25B0%25E1%25BB%259Fng-gi%25C3%25A0y-B%25C3%25ACnh-Linh-i.89572339.1492424403'
      },
      {
        image: require("../images/shop/240x240/7.jpg"),
        desc: 'Đẳng cấp thời trang, phang luôn giá cả',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2F-M%25C3%25A3-WTCHFEB-gi%25E1%25BA%25A3m-20K-%25C4%2590%25E1%25BB%2593ng-h%25E1%25BB%2593-nam-CUENA-CNA05-ch%25C3%25ADnh-h%25C3%25A3ng-d%25C3%25A2y-da-%25C4%2591%25E1%25BA%25B3ng-c%25E1%25BA%25A5p-si%25C3%25AAu-m%25E1%25BB%258Fng-c%25C3%25B3-l%25E1%25BB%258Bch-i.104765064.2585617116'
      },
      {
        image: require("../images/shop/240x240/8.jpg"),
        desc: 'Săn đồ thể thao cực chất với giá rẻ nhất',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2FGi%25C3%25A0y-%25C4%2591%25C3%25A1-b%25C3%25B3ng-s%25C3%25A2n-c%25E1%25BB%258F-nh%25C3%25A2n-t%25E1%25BA%25A1o-X19.3-%25C4%2591%25E1%25BA%25BF-TF-c%25E1%25BB%2595-cao-(4-M%25C3%25A0u)-i.56657418.2141853355'
      },
      {
        image: require("../images/shop/240x240/9.jpg"),
        desc: 'Da đẹp dáng xinh rinh luôn deal hot',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2F-M%25E1%25BB%259Bi-nh%25E1%25BA%25A5t-Son-L%25C3%25AC-L%25C3%25A2u-Tr%25C3%25B4i-Black-Rouge-Power-Proof-Matte-Tint-i.21620600.2281958329'
      },
      {
        image: require("../images/shop/240x240/14.jpg"),
        desc: 'Đồ dùng cho nhà cửa, giá chỉ còn một nửa',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2F(Ch%25E1%25BB%258Dn-m%25E1%25BA%25ABu)-B%25E1%25BB%2599-ga-k%25C3%25A8m-%25C4%2591%25C3%25B4i-v%25E1%25BB%258F-g%25E1%25BB%2591i-poly-cotton-si%25C3%25AAu-ng%25E1%25BB%2599-ngh%25C4%25A9nh-m6-m8-i.27083848.1327557522'
      },
      {
        image: require("../images/shop/240x240/15.jpg"),
        desc: 'Mùa chung đôi, đồ chơi sale sốc',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2FG%25E1%25BA%25A5u-B%25C3%25B4ng-Ch%25C3%25BA-S%25C3%25B3c-Nh%25E1%25BB%258F-Cosplay-Ng%25E1%25BB%2599-Ngh%25C4%25A9nh-%25C4%2590%25C3%25A1ng-Y%25C3%25AAu-i.4867273.7511615850'
      },
      {
        image: require("../images/shop/240x240/16.jpg"),
        desc: 'Làm đẹp cho xế với deal rẻ thích mê',
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2F-CH%25C3%258DNH-H%25C3%2583NG-N%25C3%25B3n-B%25E1%25BA%25A3o-Hi%25E1%25BB%2583m-Royal-M20C-L%25C3%25B3t-th%25C3%25A1o-R%25E1%25BB%259Di-B%25E1%25BA%25A3o-H%25C3%25A0nh-12-Th%25C3%25A1ng-i.37956747.1435152692'
      },
    ]
  };

  jump(url) {
    webview.jump({ url, title: encodeURIComponent(' ') });
  }

  handleMore = () => {
    webview.jump({ url: "http://" + window.location.host + "/static/game/shop" });
  };

  render() {
    return (
      <div className={styles.NewShopCard}>
        <Card title="Siêu giảm giá" more="shop" onMore={this.handleMore}>
          <Swiper           
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            modules={[Autoplay]}
          >
            {this.state.banner.map((item, index) => {
              return (
                <div className={styles.page} key={index}>
                  <img
                    className={styles.banner}
                    src={item.image}
                    onClick={() => this.jump(item.link)}
                    alt=""
                  />
                </div>
              );
            })}
          </Swiper>
          <If condition={this.state.goods.length > 0}>
            <Swiper
              getSwiper={swiper => (this.swiper = swiper)}
              on={{ slideChangeTransitionEnd: this.handleSlideChange }}
              slidesPerView="auto"
              slidesOffsetAfter={10 * window.rate}
            >
              {this.state.goods.map((good, index) => (
                <div
                  className={styles.item}
                  key={index}
                  onClick={() => this.jump(good.link)}
                >
                  <If condition={good.tag}>
                    <div
                      className={`${styles.tag} ${
                        good.type === 1 ? styles.type1 : ""
                      }`}
                    >
                      <i>{good.tag}</i>
                    </div>
                  </If>
                  <Report
                    height={120}
                    key={index}
                    category="e_commodity"
                    id={good.desc}
                  >
                    <img src={good.image} alt="" />
                  </Report>
                  <div className={`${styles.desc} multiRowTextOverflow`}>{good.desc}</div>
                </div>
              ))}
              <div className={styles.item} onClick={this.handleMore}>
                <div className={styles.more}>Xem thêm</div>
              </div>
            </Swiper>
          </If>
        </Card>
      </div>
    );
  }
}
