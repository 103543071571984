import React, { PureComponent } from "react";
import { getFollowList, getHistoryList, report } from "../service/api";
import styles from "./Navigation.module.scss";
import Report from "./Report";
import If from "./If";
import ImagePlaceholder from "./ImagePlaceholder";
import webview from "../utils/webview";
import { fullScreen } from "../utils/variable";

export default class Navigation extends PureComponent {
  state = {
    visible: false,
    time: 10,
    follows: [],
    historys: []
  };

  needAutoShow() {
    let date = localStorage.getItem("lastAutoShow");
    if (date && Date.now() - Number(date) < 1000 * 60 * 60 * 5) {
      this.setState({
        time: 0
      });
      return false;
    }
    localStorage.setItem("lastAutoShow", Date.now());
    return true;
  }

  autoShow() {
    this.setState({
      visible: true
    });
    this.intervalId = setInterval(() => {
      const { time } = this.state;
      if (time === 1) {
        this.hide();
        this.setState({
          time: 0
        });
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      } else {
        this.setState({
          time: time - 1
        });
      }
    }, 1000);
  }

  hide = e => {
    e && e.stopPropagation();
    this.setState({
      hiding: true
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        hiding: false
      });
    }, 190);
  };

  loadData = () => {
    this.state.follows.length === 0 &&
      this.state.follows.length !== 5 &&
      getFollowList().then(res => {
        if (!res.data || !res.data.list)
          return console.error("response error", res);
        const follows = res.data.list.splice(0, 9);
        if (follows.length === this.state.follows.length) return;
        this.setState({
          follows
        });
      });
    getHistoryList().then(res => {
      if (!res.data || !res.data.content)
        return console.error("response error", res);
      const historys = res.data.content.splice(0, 4);
      if (
        historys.length === this.state.historys.length &&
        historys[0] &&
        this.state.historys[0] &&
        historys[0].id === this.state.historys[0].id
      )
        return;
      this.setState({
        historys
      });
    });
  };

  componentDidMount() {
    this.needAutoShow() && this.autoShow();
    this.loadData();
  }

  setVisible = isVisible => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.setState({
        time: 0
      });
    }
    this.setState({
      visible: isVisible
    });
  };

  render() {
    const { historys, follows } = this.state;
    return (
      <div className={styles.Navigation}>
        <img
          className={styles.icon}
          onClick={this.setVisible.bind(this, true)}
          src={require("../images/find.png")}
          alt=""
        ></img>
        <If condition={this.state.visible}>
          <Dialog
            time={this.state.time}
            hiding={this.state.hiding}
            historys={historys}
            follows={follows}
            loadData={this.loadData}
            setVisible={this.setVisible}
            hide={this.hide}
          ></Dialog>
        </If>
      </div>
    );
  }
}

class Dialog extends PureComponent {
  link(item) {
    report.visit(item.id, "follow_borad");
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "navigator",
        applicationName: item.id
      });
    if (item.business) {
      window.location.href = `vntopnewslocal://user/businesspage?user_id=${item.id}&from=tab2`;
    } else {
      window.location.href = `vntopnews://user/homepage?user_id=${item.id}&from=tab2`;
    }
  }

  handleMore(isHistory) {
    webview.jump({
      url:
        "http://" +
        document.domain +
        "/static/game/followlist?history=" +
        (isHistory ? 1 : 0),
      page_fullscreen: fullScreen ? 1 : 0,
      page_titlebar: fullScreen ? 0 : 1
    });
  }

  componentDidMount() {
    this.props.loadData();
  }

  render() {
    const { time, follows, historys } = this.props;
    const extra =
      time !== 0 ? (
        <div className={styles.extra}>Tự đóng sau {time}s</div>
      ) : null;
    return (
      <>
        <div
          className={styles.mask}
          onClick={this.props.hide}
          onTouchMoveCapture={this.props.hide}
        ></div>
        <div
          className={`${styles.dialog} ${
            this.props.hiding ? styles.animationEnd : ""
          }`}
          style={{ marginTop: 26 }}
        >
          <If condition={historys.length === 0 && follows.length === 0}>
            <div className={styles.section}>
              <div className={styles.title}>Mới dùng gần đây</div>
              {extra}
              <div className={styles.content}>
                <div className={styles.empty}>
                  Bạn chưa xem V-Blog nào gần đây
                </div>
              </div>
            </div>
          </If>
          <If condition={historys.length !== 0}>
            <div className={styles.section}>
              <div className={styles.title}>Mới dùng gần đây</div>
              {extra}
              <div className={styles.content}>
                {historys.map(item => (
                  <div
                    className={styles.item}
                    key={item.nickname}
                    onClick={this.link.bind(this, item)}
                  >
                    <Report
                      height={37.53}
                      category={item.category === "follow_borad"}
                      id={item.id}
                    >
                      <ImagePlaceholder
                        type="avatar"
                        src={item.avatar}
                        alt=""
                      ></ImagePlaceholder>
                    </Report>
                    <div>{item.nickname}</div>
                  </div>
                ))}
                <div
                  className={styles.item}
                  onClick={() => this.handleMore(true)}
                >
                  <img src={require("../images/game_more.png")} alt=""></img>
                  <span> </span>
                </div>
              </div>
            </div>
          </If>
          <If condition={follows.length !== 0}>
            <div className={styles.section}>
              <div className={styles.title}>Theo dõi</div>
              <If condition={historys.length === 0}>{extra}</If>
              <div className={styles.content}>
                {follows.map(item => (
                  <div
                    className={styles.item}
                    key={item.nickname}
                    onClick={this.link.bind(this, item)}
                  >
                    <Report
                      height={37.53}
                      category={
                        item.category === "game"
                          ? "game"
                          : item.category === "novel"
                          ? "novel"
                          : "other"
                      }
                      id={item.id}
                    >
                      <ImagePlaceholder
                        type="avatar"
                        src={item.avatar}
                        alt=""
                      ></ImagePlaceholder>
                    </Report>
                    <div>{item.nickname}</div>
                  </div>
                ))}
                <div
                  className={styles.item}
                  onClick={() => this.handleMore(false)}
                >
                  <img src={require("../images/game_more.png")} alt=""></img>
                  <span> </span>
                </div>
              </div>
            </div>
          </If>
        </div>
      </>
    );
  }
}
