import React, { PureComponent } from 'react'
import styles from "./HeadlineCard.module.scss"

import { getMaybeLike, report } from '../service/api'
import If from '../components/If'
import webview from '../utils/webview'
import { fullScreen } from "../utils/variable"

export default class HeadlineCard extends PureComponent {

  state = {
    list: []
  }

  handleAccountClick(account) {
    if (account.business) {
      webview.jumpToBusinessPage(account.id);
    } else {
      webview.jumpToHomePage(account.id);
    }
    report.visit(account.id, "follow_board");
  }

  componentDidMount() {
    getMaybeLike({ page_token: '', list_type: 'recommend_likes' })
    // getMaybeLike({ page_token: '', list_type: 'follows' })
    .then(res => {
      this.setState({
        list: res.data.list.splice(0, 3)
      })
    })
  }

  handleMore = (isHistory) => {
    webview.jump({
      url:
        "http://" +
        document.domain +
        "/static/game/followlist?maybelike=1",
      page_fullscreen: fullScreen ? 1 : 0,
      page_titlebar: fullScreen ? 0 : 1
    });
  }

  render() {
    return (
      <If condition={this.state.list.length > 0}>
        <div className={styles.HeadlineCard}>
          {
            this.state.list.map(item => (
              <div className={styles.item} onClick={() => this.handleAccountClick(item)}>
                <img src={item.avatar} alt=""/>
                <div className={styles.name}>
                  {item.nickname}
                </div>
              </div>
            ))
          }
          <div className={styles.item} onClick={this.handleMore}>
            <img src={require('../images/more.jpg')} alt=""/>
            <div className={styles.name}>
              Xem thêm
            </div>
          </div>
        </div>
      </If>
    ) 
  } 
}