import React, { PureComponent } from 'react'

export default class LoadMore extends PureComponent {
  componentDidMount() {
    this.props.onLoadMore()
  }

  render() {
    return (
      <div style={{
        textAlign: 'center',
        fontSize: 12,
        lineHeight: '40px',
        color: '#999999'
      }}>
        Đang tải...
      </div>
    )
  }
}