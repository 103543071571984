import React, { PureComponent } from "react";
import LazyLoad from "react-lazyload";
import { report } from "../service/api";

class Point extends PureComponent {
  componentDidMount() {
    if (this.props.onReport) {
      return this.props.onReport();
    }
    if (this.props.id && this.props.category) {
      report.view(this.props.id, this.props.category);
    }
  }

  render() {
    return null;
  }
}

export default class Report extends PureComponent {
  render() {
    if (typeof this.props.startReport !== Boolean) {
      return (
        <LazyLoad
          height={this.props.height * window.rate}
          offset={this.props.offset}
        >
          <Point
            id={this.props.id}
            category={this.props.category}
            onReport={this.props.onReport}
          ></Point>
          {this.props.children}
        </LazyLoad>
      );
    } else if (this.props.startReport === true) {
      return (
        <Point
          id={this.props.id}
          category={this.props.category}
          onReport={this.props.onReport}
        ></Point>
      );
    } else {
      return null;
    }
  }
}
