import React, { PureComponent } from "react";
import styles from "./Shop.module.scss";
import Swiper from "react-id-swiper";
import webview from '../utils/webview'
import { Autoplay } from "swiper/dist/js/swiper.esm";

export default class Shop extends PureComponent {
  state = {
    banner: [
      {
        image: require('../images/shop/690X320/11.jpg'),
        link: "https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2FCollections%2F371507"
      },
      {
        image: require('../images/shop/690X320/12.jpg'),
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2Fcollections%2F312811%2F'
      },
      {
        image: require('../images/shop/690X320/13.jpg'),
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2Fcollections%2F369568%2F'
      },
      {
        image: require('../images/shop/690X320/17.jpg'),
        link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2Fcollections%2F341455%2F'
      }
    ],
    sections: [
      {
        name: "Food sale",
        goods: [
          {
            image: require("../images/shop/340_200/3.jpg"),
            desc: "Deal gần bạn! Sale off cực sâu!",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:pizza/adref:pizza/destination:https%3A%2F%2Fshopee.vn%2Fm%2Flocal-service%3Fsmtt%3D201.50098"
          },
          {
            image: require("../images/shop/340_200/12.jpg"),
            desc: "Sale off cực sâu 51%",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:foodnow/adref:foodnow/destination:https%3A%2F%2Fshopee.vn%2Fm%2FNowFood%3Fsmtt%3D201.50579"
          }
        ]
      },
      {
        name: "Đồ phụ nữ",
        goods: [
          {
            image: require("../images/shop/340_200/5.jpg"),
            desc: "Săn voucher - Không thể làm ngơ!",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:pinkqidian/adref:pinkqidian/destination:https%3A%2F%2Fshopee.vn%2Fm%2Fuu-dai-farmasi%3Fsmtt%3D201.50232"
          },
          {
            image: require("../images/shop/340_200/6.jpg"),
            desc: "Deal cực HOT! Freeship toàn quốc!",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:olay/adref:olay/destination:https%3A%2F%2Fshopee.vn%2Fm%2Fuu-dai-tu-olay%3Fsmtt%3D201.50399"
          },
          {
            image: require("../images/shop/340_200/8.jpg"),
            desc: "Deal hot chỉ 24k!",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:cos2409/adref:cos2409/destination:https%3A%2F%2Fshopee.vn%2Fm%2Fduong-da-phe-pha%3Fsmtt%3D201.50520"
          },
          {
            image: require("../images/shop/340_200/10.jpg"),
            desc: "Sale off cực sâu 48%",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:bioessence/adref:bioessence/destination:https%3A%2F%2Fshopee.vn%2Fm%2Fkhuyen-mai-bio-essence%3Fsmtt%3D200.50627"
          }
        ]
      },
      {
        name: "Đồ điện tử",
        goods: [
          {
            image: require("../images/shop/340x200/1.jpg"),
            desc: "Cơn lốc deal điện tử, săn về ngay!",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/destination:https%3A%2F%2Fshopee.vn%2Fm%2Fcuc-pham-cong-nghe"
          },
          {
            image: require("../images/shop/340_200/7.jpg"),
            desc: "Mua hàng với giá 1đ!",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:1d/adref:1d/destination:https%3A%2F%2Fshopee.vn%2Fm%2Fvanmay1d%3Fsmtt%3D201.50126"
          },
          {
            image: require("../images/shop/340_200/11.jpg"),
            desc: "Trả góp 0 lãi suất!",
            link:
              "https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:lg/adref:lg/destination:https%3A%2F%2Fshopee.vn%2Fm%2Flg-day%3Fsmtt%3D201.50603"
          }
        ]
      }
      // {
      //   image: require('../images/shop/340_200/1.jpg'),
      //   desc: 'Sale off cực sâu 32%',
      //   link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:earphone/adref:earphone/destination:https%3A%2F%2Fshopee.vn%2FTai-nghe-nh%25C3%25A9t-tai-QKZ-AK6-c%25C3%25B3-d%25C3%25A2y-ch%25E1%25BB%2591ng-%25E1%25BB%2593n-%25C3%25A2m-thanh-HiFi-ch%25E1%25BA%25A5t-l%25C6%25B0%25E1%25BB%25A3ng-cao-i.99587066.2308279523'
      // },
      // {
      //   image: require('../images/shop/340_200/2.jpg'),
      //   desc: 'Giảm giá shock!',
      //   link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:powerbank/adref:powerbank/destination:https%3A%2F%2Fshopee.vn%2FPin-d%25E1%25BB%25B1-ph%25C3%25B2ng-mini-20000mAh-h%25C3%25ACnh-tr%25C3%25B2n-th%25E1%25BB%259Di-trang-ti%25E1%25BB%2587n-d%25E1%25BB%25A5ng-i.99587066.2457005794'
      // },
      // {
      //   image: require('../images/shop/340_200/4.jpg'),
      //   desc: 'Bão sale freeship toàn quốc!',
      //   link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:durex/adref:durex/destination:https%3A%2F%2Fshopee.vn%2Fm%2Fdurex-official%3Fsmtt%3D201.49869'
      // },
      // {
      //   image: require('../images/shop/340_200/9.jpg'),
      //   desc: 'Giám giá shock 20%',
      //   link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:anker/adref:anker/destination:https%3A%2F%2Fshopee.vn%2Fa-i.16461019.2524401110'
      // },
      // {
      //   image: require('../images/shop/340_200/13.jpg'),
      //   desc: 'Giảm giá shock 31%!',
      //   link: 'https://shopee.prf.hn/click/camref:1101l7vvZ/pubref:haras99/adref:haras99/destination:https%3A%2F%2Fshopee.vn%2FT%25C3%25BAi-%25C4%2590eo-Ch%25C3%25A9o-Ch%25E1%25BB%2591ng-N%25C6%25B0%25E1%25BB%259Bc-HARAS-HR218-i.16649961.1329217493'
      // },
    ]
  };

  render() {
    return (
      <div className={styles.Shop}>
        <Swiper 
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          modules={[Autoplay]}
        >
          {this.state.banner.map((item, index) => {
            return (
              <img
                onClick={() => webview.jump({ url: item.link, title: encodeURIComponent(' ') })}
                className={styles.banner}
                src={item.image}
                key={index}
                alt=""
              />
            );
          })}
        </Swiper>
        {this.state.sections.map((section, index) => {
          return (
            <div key={index}>
              <div className={styles.title}>{section.name}</div>
              <div className={styles.row}>
                {section.goods.map((good, index) => {
                  return (
                    <div className={styles.item} key={index} onClick={() => webview.jump({ url: good.link, title: encodeURIComponent(' ') })}>
                      <img src={good.image} alt="" />
                      <div className={`${styles.desc} multiRowTextOverflow`}>
                        {good.desc}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
