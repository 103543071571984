import axios from "axios";
import { Toast } from "antd-mobile";
import { mergeToObject } from "./publicParams";

// axios.defaults.baseURL = '/tab2'
axios.interceptors.request.use(req => {
  req.headers["Content-Type"] = "application/json";
  return req;
});

axios.defaults.transformRequest = (data = {}) => {
  return JSON.stringify(data);
};
// 响应拦截
axios.interceptors.response.use(
  res => {
    res.data = res.data || {};
    if (res.status === 200 && res.data.ret === 0) {
      return res.data;
    } else if (res.data.status === 401) {
      Toast.info("hết thời gian đăng nhập");
      return Promise.reject("login timeout");
    } else {
      Toast.info(res.data.msg || res.statusText || "Lỗi không rõ");
      return Promise.reject(res.statusText);
    }
  },
  err => {
    if (err.status) {
      Toast.offline(err.statusText || "Lỗi không rõ");
      return Promise.reject(err);
    } else {
      Toast.offline("Lỗi không rõ");
      console.error(err);
      return Promise.reject(err);
    }
  }
);

export async function post(url, params = {}) {
  params = await mergeToObject(params);
  if (params.target_uid === true) {
    params.target_uid = String(params.uid);
  }
  return axios.post(url, params);
}
