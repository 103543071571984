import React, { PureComponent } from "react";
import { report } from "../service/api";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/css/swiper.css";
import styles from "./MiniApp.module.scss";
import webview from "../utils/webview";
import { fullScreen, showHeader } from "../utils/variable";
import verzon from "../utils/verzon";
import Cookies from "js-cookie";
import If from '../components/If'

const isAndroid = navigator.appVersion.indexOf("ndroid") !== -1;
const isMain = Cookies.get('flavor') === 'main'
const showBigo =
  isAndroid && verzon(Cookies.get("version")).weight >= verzon("3.18.0").weight && isMain;

export default class MiniApp extends PureComponent {
  componentDidMount() {
    this.state.apps.forEach(app => report.view(app.report_id, "navigate_bar"));
    if (this.props.subscript) {
      webview.getBigoFollowRoomInfo()
      .then(res => {
        const { apps } = this.state
        const bigo = apps.find(item => item.name == 'Bigo Live')
        if (bigo) {
          bigo.subscript = res.room_list.length
        }
        this.setState({ apps }, this.forceUpdate)
      })
    }
  }

  link(item) {
    item.report_id && report.visit(item.report_id, "navigate_bar");
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "miniApp",
        applicationName: item.id || item.name
      });
    if (item.deeplink) {
      return (window.location.href = item.deeplink);
    }
    if (!item.id) {
      return webview.jump({
        url: item.business_url,
        page_cache: 1,
        page_fullscreen: 0,
        page_titlebar: fullScreen ? 0 : 1
      });
    }
    webview.jumpToBusinessPage(item.id);
  }

  state = {
    apps: [
      // {
      //   img: require('../images/Game.png'),
      //   business_url: "http://follow.vnay.vn/static/static/game/index.html?source=tab2_navigator_gamecenter",
      //   // id: '20753375',
      //   report_id: 1,
      //   name: 'Games'
      // },
      {
        img: require("../images/shop2.png"),
        business_url: "http://shop.kumobiles.com/ldy/yougou/index.html",
        report_id: 2,
        name: "Mua sắm"
      },
      // {
      //   img: require('../images/broadcast.png'),
      //   name: 'Trực tiếp'
      // },
      // {
      //   img: require("../images/novel.png"),
      //   business_url: "http://m.medoctruyenchu.net/?author_id=20753633",
      //   report_id: 3,
      //   id: "20753633",
      //   name: "Tiểu thuyết"
      // },
      {
        img: require("../images/publish.jpg"),
        deeplink: "vntopnewslocal://moments/publish/",
        report_id: 6,
        name: "Đăng"
      },
      ...(showBigo
        ? [
            {
              img: require("../images/hot_girl.png"),
              deeplink: "vntopnewslocal://bigolive",
              report_id: 5,
              name: "Hot girls"
            }
          ]
        : []),
      {
        img: require("../images/course.png"),
        business_url: "http://" + document.domain + "/static/game/classlist",
        report_id: 4,
        name: "Khoá học"
      }
      // {
      //   img: require('../images/daichao.png'),
      //   // business_url: 'https://lottery.headlines.pw/static/v2/#/lottery/info?author_id=19840065',
      //   id: '18831292',
      //   report_id: 5,
      //   name: 'Vay tiền'
      // }
    ]
  };

  render() {
    const { apps } = this.state;
    return (
      <div
        className={styles.MiniApp}
        style={{
          paddingTop: showHeader ? 26 : 0,
          marginTop: showHeader ? "" : 0
        }}
      >
        <Swiper>
          {[apps].map((apps, index) => (
            <div className={styles.page} key={index}>
              {apps.map(app => (
                <div
                  key={app.name}
                  style={{ width: showBigo ? "25%" : "33.333%" }}
                  className={styles.item}
                  onClick={this.link.bind(this, app)}
                >
                  <If condition={app.subscript > 0}>
                    <div className={styles.subscript}>
                      { app.subscript }
                    </div>
                  </If>
                  <img src={app.img} alt="" />
                  <div>{app.name}</div>
                </div>
              ))}
            </div>
          ))}
        </Swiper>
      </div>
    );
  }
}
