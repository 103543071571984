import React, { PureComponent } from "react";
import Header from "../components/Header";

import styles from "./FollowList.module.scss";
import LazyLoad from "react-lazyload";
import LoadMore from "../components/LoadMore";
import If from "../components/If";
import Report from "../components/Report";
import { getFollowList, follow, getHistoryList, report, getMaybeLike } from "../service/api";
import webview from "../utils/webview";
import { fullScreen } from "../utils/variable";

// const covers = {
//   3149267: require('.././images/3149267.jpg'),
//   3149268: require('.././images/3149268.jpg'),
//   3149269: require('.././images/3149269.jpg'),
//   3149271: require('.././images/3149271.jpg')
// }

export default class FollowList extends PureComponent {
  state = {
    accounts: [],
    index: 0,
    loading: true,
    page_token: ''
  };

  follow = (item, index, e) => {
    e.stopPropagation()
    if (item.is_follow) return;
    item.is_follow = true;
    const { accounts } = this.state;
    accounts[index] = item;
    this.setState(
      {
        accounts
      },
      this.forceUpdate()
    );
    follow(item.id).then(res => {
    });
  };

  loadMore() {
    const isHistory = this.props.location.search.indexOf("history=1") !== -1
    const isMaybeLike = this.props.location.search.indexOf('maybelike') !== -1
    if (isMaybeLike) {
      getMaybeLike({ page_token: this.state.index === 0 ? '' : this.state.index, list_type: 'recommend_likes' })
      .then(res => {
        if (!res.data || !res.data.list) return console.error(`response error`);
        this.setState(
          {
            accounts: [...this.state.accounts, ...res.data.list.filter(item => item.nickname !== 'Tôi')],
            index: res.data.page_token || this.state.index,
            loading: false
          },
          () =>
            setTimeout(() => {
              this.setState({
                loading: res.data.page_token !== ""
              });
            }, 500)
        );
      })
    }
    else if (isHistory) {
      getHistoryList(this.state.index + 1).then(res => {
        if (!res.data || !res.data.content)
          return console.error(`response error`);
        this.setState(
          {
            accounts: [...this.state.accounts, ...res.data.content],
            index: res.data.next_page_token,
            loading: false
          },
          () =>
            setTimeout(() => {
              this.setState({
                loading: res.data.length >= 10
              });
            }, 500)
        );
      });
    } else {
      getFollowList(this.state.index === 0 ? 1 : this.state.index).then(res => {
        if (!res.data || !res.data.list) return console.error(`response error`);

        this.setState(
          {
            accounts: [...this.state.accounts, ...res.data.list],
            index: res.data.page_token || this.state.index,
            loading: false
          },
          () =>
            setTimeout(() => {
              this.setState({
                loading: res.data.page_token !== ""
              });
            }, 500)
        );
      });
    }
  }

  handleAccountClick(account) {
    if (account.business) {
      webview.jumpToBusinessPage(account.id);
    } else {
      webview.jumpToHomePage(account.id);
    }
    report.visit(account.id, "follow_board");
  }

  render() {
    return (
      <>
        <If condition={fullScreen}>
          <Header plain={true} onReturn={() => webview.close()}>
            Tất cả
          </Header>
        </If>
        <div
          className={styles.list}
          style={{ paddingTop: fullScreen ? 26 : "" }}
        >
          {this.state.accounts.map((account, index) => (
            <Report
              id={account.id}
              category="follow_board"
              height={81}
              key={index}
            >
              <div className={styles.item} onClick={() => this.handleAccountClick(account)}>
                <img
                  src={account.avatar}
                  alt=""
                  // onClick={() => this.handleAccountClick(account)}
                />
                <div className={styles.content}>
                  <div className={styles.title}>{account.nickname}</div>
                  {/* <div className={styles.meta}>{account.like_num}</div> */}
                  <div className={styles.desc}>{account.description}</div>
                </div>
                <div
                  className={`${styles.followButton} ${
                    account.is_follow ? styles.disabled : ""
                  }`}
                  onClick={(e) => this.follow(account, index, e)}
                >
                  {account.is_follow ? "Đã theo dõi" : "Theo dõi"}
                </div>
              </div>
            </Report>
          ))}
          <If condition={this.state.loading}>
            <LazyLoad overflow={false} height={40}>
              <LoadMore onLoadMore={() => this.loadMore()}></LoadMore>
            </LazyLoad>
          </If>
        </div>
      </>
    );
  }
}
