import React, { PureComponent } from "react";
import Navigation from "./Navigation";
import If from "../components/If";

import styles from "./Header.module.scss";
import { fullScreen } from "../utils/variable";

export default class Header extends PureComponent {
  render() {
    if (this.props.plain) {
      return (
        <div
          className={styles.header}
          style={{ zIndex: 800, paddingTop: fullScreen ? 26 : "" }}
        >
          <If condition={this.props.onReturn && fullScreen}>
            <img
              className={styles.back}
              onClick={this.props.onReturn}
              src={require("../images/return.png")}
              alt=""
            ></img>
          </If>
          {this.props.children}
        </div>
      );
    }
    return (
      <div
        onClick={this.handleClick}
        className={styles.header}
        style={{ zIndex: 800, paddingTop: 26 }}
      >
        {this.props.children}
        <Navigation></Navigation>
      </div>
    );
  }
}
