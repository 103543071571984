import React from 'react'
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import Home from './pages/Home'
import ClassList from './pages/ClassList'
import FollowList from './pages/FollowList'
import ShopList from './pages/ShopList'
import Shop from './pages/Shop'
import DiamondMall from './pages/DiamondMall'

// const Home = Loadable({
//   loader: () => import('./pages/Home'),
//   loading: () => null
// })

// const ClassList = Loadable({
//   loader: () => import('./pages/ClassList'),
//   loading: () => null
// })

// const FollowList = Loadable({
//   loader: () => import('./pages/FollowList'),
//   loading: () => null
// })

// const ShopList = Loadable({
//   loader: () => import('./pages/ShopList'),
//   loading: () => null
// })

// const DiamondMall = Loadable({
//   loader: () => import('./pages/DiamondMall'),
//   loading: () => null
// })

export default () => (
  <BrowserRouter basename="/static/game">
    <Route exact path="/dashboard" component={Home} />
    <Route path="/classlist" component={ClassList} />
    <Route path="/shop" component={Shop} />
    <Route path="/followlist" component={FollowList} />
    <Route path="/shoplist" component={ShopList} />
    <Route path="/diamondmall" component={DiamondMall} />
  </BrowserRouter>
)