import React, { Component } from "react";
import styles from "./App.module.scss";
import Router from "./router";

class App extends Component {
  render() {
    return (
      <div className={styles.App}>
        <div className={styles.mainMask}></div>
        <Router />
      </div>
    );
  }
}
export default App;
