var versionExp = /^(\d+)\.(\d+)\.(\d+)$/;
var debugVersionExp = /^(\d+)\.(\d+)\.(\d+)-debug$/;

function isString(str) {
  return typeof str === "string" || str instanceof String;
}

function verzon(version) {
  const instance = {
    value: String(version),
    type: "NOT_A_VERSION",
    weight: -1
  };
  if (!isString(version)) return instance;
  version = version.trim();
  //版本类型
  if (verzon.isVersion(version)) {
    instance.type = "RELEASE_VERSION";
  } else if (verzon.isDebugVersion(version)) {
    instance.type = "DEBUG_VERSION";
  } else {
    return instance;
  }
  //版本权值
  const unitArr = verzon._splitVersion(instance);
  instance.weight =
    Number(unitArr[2]) * 1 +
    Number(unitArr[1]) * 1000 +
    Number(unitArr[0]) * 1000000;
  return instance;
}

verzon.isVersion = function(version) {
  return (
    isString(version) &&
    (versionExp.test(version) || debugVersionExp.test(version))
  );
};

verzon.isReleaseVersion = function(version) {
  return isString(version) && versionExp.test(version);
};

verzon.isDebugVersion = function(version) {
  return isString(version) && debugVersionExp.test(version);
};

verzon._splitVersion = function(instance) {
  const exp =
    instance.type === "RELEASE_VERSION" ? versionExp : debugVersionExp;
  const match = instance.value.match(exp);
  return match.slice(1, 4);
};

export default verzon;
