import React, { PureComponent } from "react";
import styles from "./LifeCard.module.scss";
import Card from "./Card";
import Report from "../components/Report";
import { report } from "../service/api";

export default class LifeCard extends PureComponent {
  state = {
    lifeApps: [
      {
        img: require("../images/lottery_ticket.png"),
        name: "Xổ số",
        id: "19840065",
        business_url:
          "https://lottery.headlines.pw/static/v2/#/lottery/info?author_id=19840065"
      },
      {
        img: require("../images/city.png"),
        name: "BHXH TP.HCM",
        id: "12670886",
        business_url: "http://bhxhtphcm.gov.vn/?author_id=12670886"
      },
      {
        img: require("../images/love.png"),
        name: "iLove",
        id: "20753984"
      }
      // {
      //   img: require('../images/woomics.png'),
      //   name: 'Comic',
      //   id: '20753217'
      // },
      // {
      //   img: require('../images/borrow_money.png'),
      //   name: 'Vay tiền',
      //   id: '18831292',
      //   business_url: 'https://loan-online.vnay.vn/static/home/?author_id=18831292'
      // }
    ]
  };

  link(item) {
    report.visit(item.id, "life");
    window.dataLayer &&
      window.dataLayer.push({
        event: "click",
        action: "click",
        cardType: "life",
        applicationName: item.id
      });
    window.location.href = `vntopnewslocal://user/businesspage?user_id=${item.id}&from=tab2`;
  }

  report = () => {
    this.state.lifeApps.forEach(app => {
      report.view(app.id, "life");
    });
  };

  render() {
    return (
      <div className={styles.LifeCardWrapper}>
        <Card title="Dịch vụ" extra={false}>
          <div className={styles.content}>
            <Report height={77} offset={-4} onReport={this.report}>
              {this.state.lifeApps.map(app => (
                <div
                  className={styles.item}
                  key={app.name}
                  onClick={this.link.bind(this, app)}
                >
                  <img src={app.img} alt="" />
                  <div className={app.size === "mini" ? styles.mini : ""}>
                    {app.name}
                  </div>
                </div>
              ))}
            </Report>
          </div>
        </Card>
      </div>
    );
  }
}
