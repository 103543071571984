import React, { PureComponent } from "react";
import Header from "../components/Header";

import styles from "./ShopList.module.scss";
import If from "../components/If";
import Report from "../components/Report";
import { Autoplay } from "swiper/dist/js/swiper.esm";
import "react-id-swiper/src/styles/css/swiper.css";
import Swiper from "react-id-swiper";
import webview from "../utils/webview";
import { fullScreen } from "../utils/variable";

export default class ClassList extends PureComponent {
  state = {
    goods: [
      {
        nickname: "bàn chải đánh răng điện cấu hình cao.",
        prise: "1030000",
        oldPrise: "2030000",
        id: "20753499",
        avatar:
          "https://image2.vnay.vn/topnews-2017/imgs/7e/b2/7eb2cce8088ea43e10d5c81ee34acbab_m.jpg"
      },
      {
        nickname:
          "Thưởng thức âm thanh HiFi tạo cho bạn như đang ở buổi tiệc âm nhạc của mình ",
        prise: "688900",
        id: "20753367",
        oldPrise: "988900",
        avatar:
          "https://image2.vnay.vn/topnews-2017/imgs/b3/e2/b3e20e564fc7609a2a3b7b987e173a6e_m.jpg"
      },
      {
        nickname: "Thưởng thức thời khắc âm nhạc của chính mình.",
        prise: "350000",
        id: "20753040",
        oldPrise: "650000",
        avatar: require("../images/yinxiang.jpg")
      },
      {
        nickname: "Thưởng thức âm nhạc mọi lúc mọi nơi",
        prise: "655000",
        id: "20753827",
        oldPrise: "955000",
        avatar: require("../images/phone.jpg")
      }
      // {
      //   nickname: 'Người phiên dịch toàn cầu của bạn',
      //   prise: '2400000',
      //   id: '20753810',
      //   oldPrise: '3400000',
      //   avatar: 'http://image2.vnay.vn/topnews-2017/imgs/ca/3d/ca3d39c58618b105fec3f0cdcc921028_m.jpg'
      // }
    ],
    index: 0,
    loading: true
  };

  loadMore() {
    // getClassList(this.state.index + 1)
    // .then(res => {
    //   if (!res.data || !res.data.content) return console.error(`response error`)
    //   this.setState({
    //     classes: [...this.state.classes, ...res.data.content],
    //     index: res.data.page_token,
    //     loading: res.data.page_token === this.state.index
    //   })
    // })
  }

  render() {
    return (
      <>
        <If condition={fullScreen}>
          <Header plain={true} onReturn={() => webview.close()}>
            Mua sắm
          </Header>
        </If>
        <div
          className={styles.list}
          style={{ paddingTop: fullScreen ? 26 : "" }}
        >
          <div className={styles.swiperWrapper}>
            <Swiper
              slidesPerView={1.2}
              modules={[Autoplay]}
              initialSlide={1}
              autoplay={{
                delay: 1000
              }}
              slidesOffsetBefore={40 * window.rate}
            >
              <div onClick={() => webview.jumpToBusinessPage(20753675)}>
                <img
                  className={styles.banner}
                  src={require("../images/shop_banner_1.png")}
                  alt=""
                />
              </div>
              <div onClick={() => webview.jumpToBusinessPage(20753675)}>
                <img
                  className={styles.banner}
                  src={require("../images/shop_banner_2.png")}
                  alt=""
                />
              </div>
              <div onClick={() => webview.jumpToBusinessPage(20753675)}>
                <img
                  className={styles.banner}
                  src={require("../images/shop_banner_3.png")}
                  alt=""
                />
              </div>
            </Swiper>
          </div>
          {this.state.goods.map(good => (
            <Report id={good.id} category="e_commodity">
              <div
                className={styles.item}
                onClick={() => webview.jumpToBusinessPage(good.id)}
              >
                <img src={good.avatar} alt="" />
                <div className={styles.content}>
                  <div className={styles.title}>{good.nickname}</div>
                  <div className={styles.prise}>
                    <div className={styles.now}>
                      {(+good.prise).toLocaleString() + "d"}
                    </div>
                    <div className={styles.prev}>
                      {(+good.oldPrise).toLocaleString() + "d"}
                    </div>
                  </div>
                </div>
              </div>
            </Report>
          ))}
          {/* <If condition={this.state.loading}>
            <LazyLoad overflow={false} height={40}>
              <LoadMore onLoadMore={() => this.loadMore()}></LoadMore>
            </LazyLoad>
          </If> */}
        </div>
      </>
    );
  }
}
