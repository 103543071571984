import qs from "querystring";
let query = document.URL.split("?")[1];
export let should = true;
query = qs.parse(query);
const { version, client } = query;
if (!version || !client) {
  should = true;
} else {
  if (client.indexOf("android") === -1) {
    should = false;
  } else {
    const versions = version.split(".");
    if (versions[0] < 3) {
      should = true;
    } else if (versions[1] < 7) {
      should = true;
    } else {
      should = false;
    }
  }
}

export let fullScreen = true;
if (!version || !client) {
  fullScreen = process.env.NODE_ENV === "development";
} else {
  const versions = version.split(".").map(Number);
  if (client.indexOf("android") === -1) {
    if (Number(versions[0]) > 3) {
      fullScreen = true;
    } else if (versions[0] === 3 && versions[1] > 3) {
      fullScreen = true;
    } else if (versions[0] === 3 && versions[1] === 3 && versions[2] >= 4) {
      fullScreen = true;
    } else {
      fullScreen = false;
    }
  } else {
    fullScreen = true;
  }
}

export let showHeader = true;
if (process.env.NODE_ENV === "development") {
  showHeader = false;
} else {
  if (version && client) {
    const versions = version.split(".").map(Number);
    if (client.indexOf("android") !== -1) {
      if (versions[0] > 3) {
        showHeader = false;
      } else if (versions[0] === 3 && versions[1] > 15) {
        showHeader = false;
      } else if (versions[0] === 3 && versions[1] === 15 && versions[2] >= 0) {
        showHeader = false;
      }
    } else {
      if (versions[0] > 3) {
        showHeader = false;
      } else if (versions[0] === 3 && versions[1] > 7) {
        showHeader = false;
      } else if (versions[0] === 3 && versions[1] === 7 && versions[2] >= 0) {
        showHeader = false;
      }
    }
  }
}
